import React, { useEffect, useState } from 'react';
import { PropsWithClassProps, composeStyles } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';

export interface ProgressProps extends PropsWithClassProps<'bar' | 'outerCircle' | 'innerCircle'> {
  percentage: number;
}

export const Progress = ({ percentage, classProps }: ProgressProps) => {
  const [initiallyRendered, setInitiallyRendered] = useState(false);

  const defaultStyle = {
    root: 'h-1 w-full bg-primary/10',
    bar: twMerge(
      'relative h-full bg-primary transition-all duration-300',
      initiallyRendered ? 'opacity-100' : 'opacity-0',
    ),
    outerCircle: 'absolute -right-2 -top-2 grid size-5 animate-pulse place-items-center rounded-full bg-primary/60',
    innerCircle: 'absolute -right-1 -top-1 grid size-3 place-items-center rounded-full bg-primary',
  };

  const styles = composeStyles(defaultStyle, classProps);

  useEffect(() => {
    setTimeout(() => {
      setInitiallyRendered(true);
    }, 300);
  }, []);

  return (
    percentage >= 0 &&
    percentage <= 100 && (
      <div className={styles.root}>
        <div className={styles.bar} style={{ width: `${initiallyRendered && percentage ? percentage : 0}%` }}>
          <div className={styles.outerCircle}></div>
          <div className={styles.innerCircle}></div>
        </div>
      </div>
    )
  );
};
