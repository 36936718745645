import React, { useEffect, useState } from 'react';
import { differenceInMilliseconds, isAfter } from 'date-fns';
import { Progress, ProgressProps } from '@components/atoms/Progress/Progress';
import { TvChannelShowtime } from '@src/lib/graphql/generated';
import { useCurrentTime } from '@src/lib/hooks/useCurrentTime';
import { createDate } from '@src/utils/dateHelpers';

export interface ShowtimeProgressProps {
  showtime: TvChannelShowtime;
  progressClassProps?: ProgressProps['classProps'];
  onShowEnded?: () => void;
}

export const ShowtimeProgress = ({ showtime, onShowEnded, progressClassProps }: ShowtimeProgressProps) => {
  const [showEnded, setShowEnded] = useState(false);
  const { currentTime } = useCurrentTime();

  useEffect(() => {
    setShowEnded(false);
  }, [showtime]);

  if (!showtime || showEnded) return null;

  const startTime = createDate(showtime?.start);
  const endTime = createDate(showtime?.stop);
  let progress = 0;

  if (isAfter(currentTime, endTime)) {
    setShowEnded(true);
    !!onShowEnded && onShowEnded();
  } else {
    progress = (differenceInMilliseconds(startTime, currentTime) / differenceInMilliseconds(startTime, endTime)) * 100;
    progress = progress > 100 ? 100 : progress;
  }

  return <Progress percentage={progress} classProps={progressClassProps} />;
};
